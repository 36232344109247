import mixpanel from 'mixpanel-browser'

const mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN

if (mixpanelToken && !mixpanel.__loaded) {
  mixpanel.init(mixpanelToken)
}

let actions = {
  identify: async (id) => {
    if (!mixpanelToken) {return}
    return mixpanel.identify(id)
  },
  alias: async (id) => {
    if (!mixpanelToken) {return}
    return mixpanel.alias(id)
  },
  track: async (name, props) => {
    if (!mixpanelToken) {return}
    return mixpanel.track(name, props)
  },
  people: {
    set: async (props) => {
      if (!mixpanelToken) {return}
      return mixpanel.people.set(props)
    },
  },
}

export let Mixpanel = actions
