import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import biometrics from './modules/biometrics'
import patient from './modules/patient'
import practice from './modules/practice'
import axios from 'axios'

const production = process.env.VUE_APP_NODE_ENV === 'production'

axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
axios.interceptors.request.use(
  (config) => {
    let token = auth.state.token
    if (token) {
      config.headers['Authorization'] = `Token ${token}`
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const intercepted_methods = ['PUT', 'DELETE'];
const open = XMLHttpRequest.prototype.open;

function appendSlash() {
  if (intercepted_methods.includes(arguments[0]) && !arguments[1].endsWith("/")) {
    arguments[1] += "/";
  }
  return open.apply(this, arguments);
}

XMLHttpRequest.prototype.open = appendSlash;

export default new Vuex.Store({
  modules: {
    auth,
    user,
    biometrics,
    patient,
    practice,
  },
  strict: !production
})
