import axios from 'axios'
import * as patientActions from './actions/patient'

const urlMaker = (container, patient_id, method, id='') => {
  if (method === 'post') {
    return `/${container}/`
  } else if(method === 'get') {
    return `/patients/${patient_id}/${container}/`
  } else if(method === 'patch' || method === 'delete') {
    return `/${container}/${id}/`
  }
}

const patientCheckCall = (container) => {
  return ({dispatch, state}, options={}) => {
    if (state[container].hasLoadedOnce) {
      return Promise.resolve()
    }
    return dispatch(patientActions[`PATIENT_${container.toUpperCase()}_REQUEST`], options)
  }
}

const patientActionCall = (container, ops={}) => {
  return ({commit, rootState}, options={}) => {
    let postfix = ''
    const method = ops.method || options.method || 'get'
    if(method==='post') {
      postfix = 'CREATE_'
    } else if (method==='patch') {
      postfix = 'UPDATE_'
    } else if (method==='delete') {
      postfix = 'DELETE_'
    } else {
      commit(patientActions[`PATIENT_${container.toUpperCase()}_REQUEST`])
    }

    const patient_id = rootState.user.profile.role_data.id
    const data = options.data || {}
    const url = urlMaker(container, patient_id, method, data.id)
    const params = options.params || {}

    const success = (resp) => {
      return commit(patientActions[`PATIENT_${container.toUpperCase()}_${postfix}SUCCESS`], resp)
    }
    const error = (err) => {
      return commit(patientActions[`PATIENT_${container.toUpperCase()}_${postfix}ERROR`], err)
    }
    return axios({
      method: method,
      url: url,
      data: data,
      params: params
    }).then(success).catch(error)
  }
}

const requestMutation = (container) => {
  return (state) => {
    state[container].status = 'loading'
  }
}

const successMutation = (container) => {
  return (state, resp) => {
    state[container].status = 'success'
    state[container].data = resp.data
    state[container].hasLoadedOnce = true
    return resp
  }
}

const errorMutation = (container) => {
  return (state, err) => {
    state[container].status = 'error'
    state[container].errors = err.response
    return err
  }
}

const createSuccessMutation = (container) => {
  return (state, resp) => {
    let data = resp.data
    if(Array.isArray(state[container].data)) {
      if(!Array.isArray(data)) {
        data = [data]
      }
      state[container].data = data.concat(state[container].data)
    } else {
      state[container].data = resp.data
    }
  }
}

const createErrorMutation = () => {
  return (state, err) => {
    throw(err)
  }
}

const updateSuccessMutation = (container) => {
  return (state, resp) => {
    let id = resp.data.id
    if(Array.isArray(state[container].data)) {
      let data = state[container].data.map((el) => {
        if(el.id===id) {
          return resp.data
        } else {
          return el
        }
      })
      state[container].data = data
    } else {
      state[container].data = resp.data
    }
  }
}

const updateErrorMutation = () => {
  return (state, err) => {
    throw(err)
  }
}

const deleteSuccessMutation = (container) => {
  return (state, resp) => {
    try {
      var patient_id = resp.config.url.split("/").slice(-2)[0]
    } catch {
      return
    }
    let data = state[container].data.filter(el => el.id != patient_id)
    state[container].data = data
  }
}

const deleteErrorMutation = () => {
  return (state, err) => {
    throw(err)
  }
}

export {
  urlMaker,
  patientCheckCall,
  patientActionCall,
  requestMutation,
  successMutation,
  errorMutation,
  createSuccessMutation,
  createErrorMutation,
  updateSuccessMutation,
  updateErrorMutation,
  deleteSuccessMutation,
  deleteErrorMutation
}
