import * as practiceActions from '../actions/practice'
import {default as global} from '../index'
import axios from 'axios'

const getDefaultState = () => {
  return {
    practice: {status: '', hasLoadedOnce: false, data: {}},
  }
}

const state = getDefaultState()

const getters = {
  currentPractice: state => state.practice || {},
}

const actions = {
  [practiceActions.PRACTICE_REQUEST]: ({commit}) => {
    commit(practiceActions.PRACTICE_REQUEST)
    const practice_id = global.getters.practiceID
    const success = (resp) => {
      commit(practiceActions.PRACTICE_REQUEST_SUCCESS, resp)
    }
    const error = (resp) => {
      commit(practiceActions.PRACTICE_REQUEST_ERROR, resp)
      return resp
    }
    return axios.get(`/practices/${practice_id}/`).then(success).catch(error)
  },
  [practiceActions.PRACTICE_CHECK]: ({dispatch, state}) => {
    let promise
    const practice_id = global.getters.practiceID
    if (practice_id && !(state.practice && state.practice.data && state.practice.data.id)) {
      promise = dispatch(practiceActions.PRACTICE_REQUEST)
    } else {
      promise = Promise.resolve()
    }
    return promise
  },
}

const mutations = {
  [practiceActions.PRACTICE_REQUEST]: (state) => {
    state.practice.status = 'loading'
  },
  [practiceActions.PRACTICE_REQUEST_SUCCESS]: (state, resp) => {
    state.practice.status = 'success'
    state.practice.data = resp.data
    state.practice.hasLoadedOnce = true
  },
  [practiceActions.PRACTICE_REQUEST_ERROR]: (state) => {
    state.practice.status = 'error'
    state.practice.hasLoadedOnce = true
  },
}


export default {
  state,
  actions,
  getters,
  mutations,
}
