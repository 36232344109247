import { FingerprintAIO } from "@ionic-native/fingerprint-aio"
import { isPlatform } from "@ionic/vue"
import store from "@/store"
import {
  BIOMETRICS_DISABLE,
  BIOMETRICS_ENABLE,
  BIOMETRICS_SUCCESS,
  BIOMETRICS_ERROR,
} from "@/store/actions/biometrics"

const fallbackButtonTitle = isPlatform('ios') ? 'Use Pin' : 'Use Other'
const MAX_REGISTER_RETRIES = 3
const MAX_LOAD_RETRIES = 3
let maxRegisterRetries = MAX_REGISTER_RETRIES
let maxLoadRetries = MAX_LOAD_RETRIES

// BIOMETRIC_DISMISSED, -108, touch above the biometric dialog. "BIOMETRIC_DISMISSED"
// Authentication failed, -102, Wrong finger. "BIOMETRIC_AUTHENTICATION_FAILED"
// BIOMETRIC_PIN_OR_PATTERN_DISMISSED, -109, Cancel PIN, "BIOMETRIC_PIN_OR_PATTERN_DISMISSED"
// Too many attempts., -111, "BIOMETRIC_LOCKED_OUT"

function service() {
    const registerBiometricSecret = (secret) => {
        return FingerprintAIO.registerBiometricSecret({
            description: 'Enable biometric login',
            secret: secret,
            invalidateOnEnrollment: true,
            confirmationRequired: true,
            fallbackButtonTitle: fallbackButtonTitle
        }, successCallback, errorCallback);

        function successCallback() {
            maxRegisterRetries = MAX_REGISTER_RETRIES
            store.commit(BIOMETRICS_ENABLE)
        }

        function errorCallback(error) {
            if (error.code === -108 || error.code === -109 || error.code === -111) {
                maxRegisterRetries = MAX_REGISTER_RETRIES
                store.commit(BIOMETRICS_DISABLE)
            } else if (maxRegisterRetries > 0) {
                maxRegisterRetries = maxRegisterRetries - 1
                registerBiometricSecret(secret)
            } else {
                maxRegisterRetries = MAX_REGISTER_RETRIES
                store.commit(BIOMETRICS_DISABLE)
            }
        }
    }
    const loadBiometricSecret = () => {
        return FingerprintAIO.loadBiometricSecret({
            description: 'Log in with biometrics',
            allowBackup: true,
            fallbackButtonTitle: fallbackButtonTitle
        }, successCallback, errorCallback);

        function successCallback(secret) {
            maxLoadRetries = MAX_LOAD_RETRIES
            store.dispatch(BIOMETRICS_SUCCESS, {token: secret})
        }

        function errorCallback(error) {
            if (error.code === -108 || error.code === -109 || error.code === -111) {
                maxLoadRetries = MAX_LOAD_RETRIES
                store.commit(BIOMETRICS_ERROR)
            } else if (maxLoadRetries > 0) {
                maxLoadRetries = maxLoadRetries - 1
                loadBiometricSecret()
            } else {
                maxLoadRetries = MAX_LOAD_RETRIES
                store.dispatch(BIOMETRICS_ERROR)
            }
        }
    }
    const isFingerPrintAvailable = (secret) => {
        return FingerprintAIO.isAvailable(isAvailableSuccess, isAvailableError)

        function isAvailableSuccess() {
            if (secret) {
                registerBiometricSecret(secret)
            } else {
                loadBiometricSecret()
            }
        }

        function isAvailableError() {
            store.commit(BIOMETRICS_DISABLE)
        }
    }
    return {
        isFingerPrintAvailable,
        loadBiometricSecret,
        registerBiometricSecret
    }
}

export default service;
