<template>
  <ion-app>
    <ion-router-outlet/>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue'

export default {
  name: 'App',
  components: { IonApp, IonRouterOutlet },
}
</script>

<style lang="scss">
  /* Core CSS required for Ionic components to work properly */
  @import '~@ionic/vue/css/core.css';
  /* Basic CSS for apps built with Ionic */
  @import '~@ionic/vue/css/normalize.css';
  @import '~@ionic/vue/css/structure.css';
  @import '~@ionic/vue/css/typography.css';
  /* Optional CSS utils that can be commented out */
  @import '~@ionic/vue/css/padding.css';
  @import '~@ionic/vue/css/float-elements.css';
  @import '~@ionic/vue/css/text-alignment.css';
  @import '~@ionic/vue/css/text-transformation.css';
  @import '~@ionic/vue/css/flex-utils.css';
  @import '~@ionic/vue/css/display.css';
  /* Theme variables */
  @import './theme/variables.css';
  /* Fonts */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./assets/fonts/source_sans_pro_latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
</style>
