import axios from 'axios'
import router from '@/router'
import { Capacitor } from '@capacitor/core'
import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT } from '../actions/auth'
import { USER_REQUEST, RESET_USER_STATE } from '../actions/user'
import { BIOMETRICS_DISABLE } from '../actions/biometrics'
import biometrics from '@/services/biometrics'
import { Mixpanel } from '@/services/mixpanel'
import { default as global } from '../index'

const isNativePlatform = Capacitor.isNativePlatform()

const getDefaultState = () => {
  let token = ''
  if(!isNativePlatform){
    token = localStorage.getItem('authtoken') || ''
  }
  return {token: token, status: '', hasLoadedOnce: false}
}

const state = getDefaultState()

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  sessionToken: state => state.token,
}

const actions = {
  [AUTH_REQUEST]: ({commit, dispatch}, data) => {
    data = data || {}
    if(isNativePlatform){
      data.mobile = true
    }
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      return axios({
        method: 'post',
        url: '/patient_app/verify_code/',
        data: data
      })
      .then((resp) => {
        if (isNativePlatform) {
          biometrics().isFingerPrintAvailable(resp.data.token)
        } else {
          localStorage.setItem('authtoken', resp.data.token)
        }
        commit(AUTH_SUCCESS, resp.data.token)
        dispatch(USER_REQUEST).then(() => {
          let profile = global.getters.userProfile
          Mixpanel.identify(profile.username)
          Mixpanel.people.set({
            "$email": profile.email || (profile.role_data || {}).email || '',
            "USER_ID": profile.username,
            "first_name": profile.first_name,
            "last_name": profile.last_name,
            "role": profile.role,
            "role_type": (profile.role_data || {}).type || '',
          })
          let platform = isNativePlatform ? 'mobile' : 'web'
          Mixpanel.track(`${platform}_login`)
          let rpm_status = ((profile || {}).role_data || {}).rpm_status
          let rpmAvailable = ['enrolled', 'pending'].includes(rpm_status)
          let path = '/messages'
          if (rpmAvailable) {
            path = 'monitor'
          } else {
            let rtm_status = ((profile || {}).role_data || {}).rtm_status
            let rtmAvailable = ['enrolled', 'pending'].includes(rtm_status)
            if (rtmAvailable) {
              path = 'pain'
            }
          }
          router.push(path)
        })
        resolve(resp)
      })
      .catch((err) => {
        commit(AUTH_ERROR, err)
        if (isNativePlatform && 'code' in data) {
          commit(BIOMETRICS_DISABLE)
        } else {
          localStorage.removeItem('authtoken')
        }
        reject(err)
      })
    })
  },
  [AUTH_LOGOUT]: ({commit, dispatch}) => {
    return new Promise((resolve) => {
      let platform = isNativePlatform ? 'mobile' : 'web'
      Mixpanel.track(`${platform}_logout`)
      if (isNativePlatform) {
        commit(BIOMETRICS_DISABLE)
      } else {
        localStorage.removeItem('authtoken')
      }
      commit(AUTH_LOGOUT)
      dispatch(RESET_USER_STATE)
      resolve()
    })
  },
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = 'success'
    state.token = token
    state.hasLoadedOnce = true
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
