import router from '../../router'
import {BIOMETRICS_LOGIN, BIOMETRICS_SUCCESS, BIOMETRICS_ERROR, BIOMETRICS_ENABLE, BIOMETRICS_DISABLE,
  BIOMETRICS_ENABLE_LOADING, BIOMETRICS_DISABLE_LOADING } from '../actions/biometrics'
import { USER_REQUEST } from '../actions/user'
import biometrics from "../../services/biometrics"
import { Capacitor } from '@capacitor/core'
import {AUTH_ERROR, AUTH_SUCCESS} from "../actions/auth"
import { Mixpanel } from '@/services/mixpanel'
import { default as global } from '../index'
import axios from 'axios'

const isNativePlatform = Capacitor.isNativePlatform()

const getDefaultState = () => {
  let locked = false
  if(isNativePlatform){
    locked = !!localStorage.getItem('locked')
  }
  return {locked: locked, biometricsLoading: false, biometricsSuccess: false, status: ''}
}

const state = getDefaultState()

const getters = {
  locked: state => state.locked,
  biometricsLoading: state => state.biometricsLoading,
  biometricsSuccess: state => state.biometricsSuccess,
}

const actions = {
  [BIOMETRICS_LOGIN]: () => {
    return new Promise(() => {
      return biometrics().isFingerPrintAvailable()
    })
  },
  [BIOMETRICS_SUCCESS]: ({commit, dispatch}, data) => {
    commit(BIOMETRICS_SUCCESS)
    commit(AUTH_SUCCESS, data.token)
    dispatch(USER_REQUEST).then(() => {
      axios.post('/users/record_loop_login/')
      let profile = global.getters.userProfile
      Mixpanel.identify(profile.username)
      Mixpanel.people.set({
        "$email": profile.email || (profile.role_data || {}).email || '',
        "USER_ID": profile.username,
        "first_name": profile.first_name,
        "last_name": profile.last_name,
        "role": profile.role,
        "role_type": (profile.role_data || {}).type || '',
      })
      Mixpanel.track('biometrics_success')
      let rpm_status = ((profile || {}).role_data || {}).rpm_status
      let rpmAvailable = ['enrolled', 'pending'].includes(rpm_status)
      let path = '/messages'
      if (rpmAvailable) {
        path = 'monitor'
      } else {
        let rtm_status = ((profile || {}).role_data || {}).rtm_status
        let rtmAvailable = ['enrolled', 'pending'].includes(rtm_status)
        if (rtmAvailable) {
          path = 'pain'
        }
      }
      router.push(path)
      commit(BIOMETRICS_DISABLE_LOADING)
    })
  },
  [BIOMETRICS_ERROR]: ({commit}) => {
    Mixpanel.track('biometrics_error')
    commit(BIOMETRICS_DISABLE_LOADING)
    commit(BIOMETRICS_DISABLE)
    commit(AUTH_ERROR)
  }
}

const mutations = {
  [BIOMETRICS_ENABLE]: (state) => {
    Mixpanel.track('biometrics_enabled')
    localStorage.setItem('locked', 'true')
    state.locked = true
  },
  [BIOMETRICS_DISABLE]: (state) => {
    Mixpanel.track('biometrics_disabled')
    localStorage.setItem('locked', '')
    state.locked = false
  },
  [BIOMETRICS_SUCCESS]: (state) => {
    state.biometricsSuccess = true
  },
  [BIOMETRICS_ENABLE_LOADING]: (state) => {
    state.biometricsLoading = true
  },
  [BIOMETRICS_DISABLE_LOADING]: (state) => {
    state.biometricsLoading = false
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
