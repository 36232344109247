export const PATIENT_RPM_VITALS_CHECK = 'PATIENT_RPM_VITALS_CHECK'
export const PATIENT_RPM_VITALS_REQUEST = 'PATIENT_RPM_VITALS_REQUEST'
export const PATIENT_RPM_VITALS_SUCCESS = 'PATIENT_RPM_VITALS_SUCCESS'
export const PATIENT_RPM_VITALS_ERROR = 'PATIENT_RPM_VITALS_ERROR'

export const PATIENT_RPM_DEVICES_CHECK = 'PATIENT_RPM_DEVICES_CHECK'
export const PATIENT_RPM_DEVICES_REQUEST = 'PATIENT_RPM_DEVICES_REQUEST'
export const PATIENT_RPM_DEVICES_SUCCESS = 'PATIENT_RPM_DEVICES_SUCCESS'
export const PATIENT_RPM_DEVICES_ERROR = 'PATIENT_RPM_DEVICES_ERROR'

export const PATIENT_RTM_VITALS_CHECK = 'PATIENT_RTM_VITALS_CHECK'
export const PATIENT_RTM_VITALS_REQUEST = 'PATIENT_RTM_VITALS_REQUEST'
export const PATIENT_RTM_VITALS_SUCCESS = 'PATIENT_RTM_VITALS_SUCCESS'
export const PATIENT_RTM_VITALS_ERROR = 'PATIENT_RTM_VITALS_ERROR'

export const PATIENT_PAIN_LOCATIONS_CHECK = 'PATIENT_PAIN_LOCATIONS_CHECK'
export const PATIENT_PAIN_LOCATIONS_REQUEST = 'PATIENT_PAIN_LOCATIONS_REQUEST'
export const PATIENT_PAIN_LOCATIONS_SUCCESS = 'PATIENT_PAIN_LOCATIONS_SUCCESS'
export const PATIENT_PAIN_LOCATIONS_ERROR = 'PATIENT_PAIN_LOCATIONS_ERROR'

export const PATIENT_RPM_VITAL_NOTIFICATIONS_CHECK = 'PATIENT_RPM_VITAL_NOTIFICATIONS_CHECK'
export const PATIENT_RPM_VITAL_NOTIFICATIONS_REQUEST = 'PATIENT_RPM_VITAL_NOTIFICATIONS_REQUEST'
export const PATIENT_RPM_VITAL_NOTIFICATIONS_SUCCESS = 'PATIENT_RPM_VITAL_NOTIFICATIONS_SUCCESS'
export const PATIENT_RPM_VITAL_NOTIFICATIONS_ERROR = 'PATIENT_RPM_VITAL_NOTIFICATIONS_ERROR'
