import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cleave from 'cleave.js'
import 'cleave.js/dist/addons/cleave-phone.us'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { Capacitor } from '@capacitor/core'
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

const app = createApp(App)
  .use(IonicVue, {swipeBackEnabled: false})
  .use(store)
  .use(router)

app.directive('cleave', {
  mounted (el, binding) {
    el.cleave = new Cleave(el, binding.value || {})
  },
  updated (el) {
    el.value = el.cleave.properties.result
    const event = new Event('input', { bubbles: true })
    el.dispatchEvent(event)
  }
})

const sentryDSN = process.env.VUE_APP_SENTRY_DSN

if (sentryDSN) {
  Sentry.init({
    app,
    dsn: sentryDSN,
    release: '20240227-1',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_API_HOST, process.env.VUE_APP_APP_HOST],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend decreasing this value in production.
    tracesSampleRate: 1.0,
  })
}

// Add or remove the "dark" class based on if the media query matches
function toggleDarkTheme (shouldAdd) {
  document.body.classList.toggle('dark', shouldAdd)
}

// Use matchMedia to check the user preference
const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
toggleDarkTheme(prefersDark.matches)

// Listen for changes to the prefers-color-scheme media query
prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches))

// Android fix for enabling dark mode, see native implementation in MainActivity.java
if (window.navigator.userAgent.includes('AndroidDarkMode')) {
     toggleDarkTheme(true)
}

if ('serviceWorker' in navigator && !Capacitor.isNative) {
  navigator.serviceWorker.register('/service_worker.js')
}

router.isReady().then(() => {
  app.mount('#app')
});
