import * as patientActions from '../actions/patient'
import * as utils from '../utils'

const getDefaultSubState = (init={}) => {
  return {
    status: '',
    hasLoadedOnce: false,
    data: init
  }
}

const getDefaultState = () => {
  return {
    rpm_vitals: getDefaultSubState({}),
    rpm_devices: getDefaultSubState([]),
    rtm_vitals: getDefaultSubState({}),
    pain_locations: getDefaultSubState([]),
    rpm_vital_notifications: getDefaultSubState([]),
  }
}

let state = getDefaultState()

const getters = {
  patientRPMVitals: state => state.rpm_vitals || getDefaultSubState({}),
  patientRPMDevices: state => state.rpm_devices || getDefaultSubState([]),
  patientRTMVitals: state => state.rtm_vitals || getDefaultSubState({}),
  patientPainLocations: state => state.pain_locations || getDefaultSubState([]),
  patientRPMVitalNotifications: state => state.rpm_vital_notifications || getDefaultSubState([]),
}

const actions = {
  [patientActions.PATIENT_RPM_VITALS_CHECK]: utils.patientCheckCall('rpm_vitals'),
  [patientActions.PATIENT_RPM_VITALS_REQUEST]: utils.patientActionCall('rpm_vitals'),

  [patientActions.PATIENT_RPM_DEVICES_CHECK]: utils.patientCheckCall('rpm_devices'),
  [patientActions.PATIENT_RPM_DEVICES_REQUEST]: utils.patientActionCall('rpm_devices'),

  [patientActions.PATIENT_RTM_VITALS_CHECK]: utils.patientCheckCall('rtm_vitals'),
  [patientActions.PATIENT_RTM_VITALS_REQUEST]: utils.patientActionCall('rtm_vitals'),

  [patientActions.PATIENT_PAIN_LOCATIONS_CHECK]: utils.patientCheckCall('pain_locations'),
  [patientActions.PATIENT_PAIN_LOCATIONS_REQUEST]: utils.patientActionCall('pain_locations'),

  [patientActions.PATIENT_RPM_VITAL_NOTIFICATIONS_CHECK]: utils.patientCheckCall('rpm_vital_notifications'),
  [patientActions.PATIENT_RPM_VITAL_NOTIFICATIONS_REQUEST]: utils.patientActionCall('rpm_vital_notifications'),
}

const mutations = {
  [patientActions.PATIENT_RPM_VITALS_REQUEST]: utils.requestMutation('rpm_vitals'),
  [patientActions.PATIENT_RPM_VITALS_SUCCESS]: utils.successMutation('rpm_vitals'),
  [patientActions.PATIENT_RPM_VITALS_ERROR]: utils.errorMutation('rpm_vitals'),

  [patientActions.PATIENT_RPM_DEVICES_REQUEST]: utils.requestMutation('rpm_devices'),
  [patientActions.PATIENT_RPM_DEVICES_SUCCESS]: utils.successMutation('rpm_devices'),
  [patientActions.PATIENT_RPM_DEVICES_ERROR]: utils.errorMutation('rpm_devices'),

  [patientActions.PATIENT_RTM_VITALS_REQUEST]: utils.requestMutation('rtm_vitals'),
  [patientActions.PATIENT_RTM_VITALS_SUCCESS]: utils.successMutation('rtm_vitals'),
  [patientActions.PATIENT_RTM_VITALS_ERROR]: utils.errorMutation('rtm_vitals'),

  [patientActions.PATIENT_PAIN_LOCATIONS_REQUEST]: utils.requestMutation('pain_locations'),
  [patientActions.PATIENT_PAIN_LOCATIONS_SUCCESS]: utils.successMutation('pain_locations'),
  [patientActions.PATIENT_PAIN_LOCATIONS_ERROR]: utils.errorMutation('pain_locations'),

  [patientActions.PATIENT_RPM_VITAL_NOTIFICATIONS_REQUEST]: utils.requestMutation('rpm_vital_notifications'),
  [patientActions.PATIENT_RPM_VITAL_NOTIFICATIONS_SUCCESS]: utils.successMutation('rpm_vital_notifications'),
  [patientActions.PATIENT_RPM_VITAL_NOTIFICATIONS_ERROR]: utils.errorMutation('rpm_vital_notifications'),
}

export default {
  actions,
  state,
  getters,
  mutations,
}
