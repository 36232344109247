import axios from 'axios'
import { USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_CHECK, RESET_USER_STATE } from '../actions/user'
import { AUTH_LOGOUT } from '../actions/auth'

const getDefaultState = () => {
  return {
    status: '',
    profile: {},
    practice_id: localStorage.getItem('practiceID') || '',
  }
}
const state = getDefaultState()
const getters = {
  userProfile: state => state.profile || {},
  isProfileLoaded: state => !!state.profile.name,
  practiceID: state => (state.practice_id || localStorage.getItem('practiceID')),
}

const actions = {
  [USER_REQUEST]: ({commit, dispatch}) => {
    commit(USER_REQUEST)
    return axios({
      method: 'get',
      url: '/users/me/'
    })
    .then(response => {
      commit(USER_SUCCESS, response)
    })
    .catch(() => {
      commit(USER_ERROR)
      dispatch(AUTH_LOGOUT) // if response is unauthorized, logout
    })
  },
  [USER_CHECK]: ({state, dispatch}) => {
    let promise
    if (!state.profile.id) {
      promise = dispatch(USER_REQUEST)
    } else {
      promise = Promise.resolve()
    }
    return promise
  },
  [RESET_USER_STATE]: ({ commit }) => {
    commit(RESET_USER_STATE)
  },
}

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [USER_SUCCESS]: (state, response) => {
    state.status = 'success'
    state.profile = response.data
    if (!state.practice_id) {
      var practice_id = ((response.data || {}).role_data || {}).practice
      state['practice_id'] = practice_id
      localStorage.setItem('practiceID', state.practice_id)
    }
  },
  [USER_ERROR]: (state) => {
    state.status = 'error'
  },
  [RESET_USER_STATE]: (state) => {
    Object.assign(state, getDefaultState())
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
