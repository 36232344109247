import { createRouter, createWebHistory } from '@ionic/vue-router'
import store from '../store'
import { USER_CHECK } from '../store/actions/user'
import { PRACTICE_CHECK } from '../store/actions/practice'

// Containers
const MainContainer = () => import('@/containers/MainContainer')
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const Login = () => import('@/views/Login')
const Messages = () => import('@/views/Messages')
const Profile = () => import('@/views/Profile')
const Monitor = () => import('@/views/Monitor')
const Pain = () => import('@/views/Pain')
const Vitals = () => import('@/views/Vitals')
const DexcomLoginReturn = () => import('@/views/DexcomLoginReturn')
const PracticeError = () => import('@/views/PracticeError')


const routes = [
  {
    path: '/dexcom_login_return',
    component: DexcomLoginReturn,
  },
  {
    path: '',
    component: MainContainer,
    children: [
      {
        path: '',
        component: DefaultContainer,
        children: [
          {
            path: '/messages',
            component: Messages,
          },
          {
            path: '/profile',
            component: Profile,
          },
          {
            path: '/monitor',
            component: Monitor,
          },
          {
            path: '/vitals/:vitalType',
            component: Vitals,
          },
          {
            path: '/pain',
            component: Pain,
          },
        ]
      },
      {
        path: '/login',
        component: Login,
      },
      {
        path: '/practice_error',
        component: PracticeError,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  if (to.path === '/dexcom_login_return') {
    return true
  }
  if (to.path === '/practice_error') {
    return true
  }
  let toLogin = to.path === '/login'
  if (!store.getters.isAuthenticated) {
    return toLogin ? true : '/login'
  }
  if (toLogin && 't' in to.query && 'c' in to.query) {
    return true
  }
  await store.dispatch(USER_CHECK)
  let error = await store.dispatch(PRACTICE_CHECK)
  if (error && error.response && error.response.status === 403) {
    return '/practice_error'
  }
  let toHomeOrLogin = toLogin || to.path === '/'
  let toVitals = to.path.startsWith('/vitals')
  let toRpm = to.path === '/monitor'
  let toRtm = to.path === '/pain'
  if (!toHomeOrLogin && !toRpm && !toRtm && !toVitals) {
    return true
  }
  let rpm_status = ((store.getters.userProfile || {}).role_data || {}).rpm_status
  let rpmAvailable = ['enrolled', 'pending'].includes(rpm_status)
  let rtm_status = ((store.getters.userProfile || {}).role_data || {}).rtm_status
  let rtmAvailable = ['enrolled', 'pending'].includes(rtm_status)
  if (!rpmAvailable && !rtmAvailable) {
    return '/messages'
  } else if (rpmAvailable) {
    return toHomeOrLogin || toRtm ?  '/monitor' : true
  } else {
    return toHomeOrLogin || toRpm ?  '/pain' : true
  }
})

export default router
